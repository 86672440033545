



























import { Component, Vue } from 'vue-property-decorator'
// import AddSwap from '@/components/AddSwap.vue'

@Component({
  // components: { AddSwap }
})
export default class V4dev extends Vue {
  jwt: string = ""
  jwtParsed: string = ""

  swap: boolean = false

  async mounted (): Promise<void> {
    this.jwt = this.$store.state.jwt
    this.jwtParsed = JSON.stringify(this.$store.getters.parsedJWT, null, 2)
  }

  async copy (text: string): Promise<void> {
    await navigator.clipboard.writeText(text)
  }
}
